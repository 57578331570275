import { isPlatformServer } from '@angular/common';
import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { REQUEST } from '@knorr-bremse-portals/ngx-components';

declare interface Navigator {
  languages: string[];
}
declare const navigator: Navigator;

export type Locale = {
  region: string;
  language: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserLocaleService {

  private platformId = inject(PLATFORM_ID);
  private request = inject(REQUEST, { optional: true });

  private cachedUserLocales?: Locale[];

  public getUserLocales(): Locale[] {
    if (this.cachedUserLocales) {
      return this.cachedUserLocales;
    }

    let browserLocales: string[];
    if (isPlatformServer(this.platformId)) {
      browserLocales = this.request ? this.request.acceptsLanguages() : [];
    } else {
      browserLocales = navigator.languages;
    }

    const locales = browserLocales.map(locale => {
      const [language, region] = locale.split('-');
      return {
        language,
        region,
      };
    });

    // Fill all missing regions with the first region found
    const firstLocaleWithRegion = locales.find(locale => locale.region !== undefined);
    if (firstLocaleWithRegion !== undefined) {
      locales.filter(locale => locale.region === undefined).forEach(locale => locale.region = firstLocaleWithRegion.region);
    }

    this.cachedUserLocales = locales;
    return this.cachedUserLocales;
  }

  public getPrimaryRegion(): string | undefined {
    return this.getUserLocales()[0]?.region;
  }

  public getPrimaryLanguage(): string | undefined {
    return this.getUserLocales()[0]?.language;
  }

}
